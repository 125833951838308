import type { ButtonHTMLAttributes, DetailedHTMLProps, Ref, ReactNode, LegacyRef } from "react";

import { Link, type To } from "@shared/react-router-dom";

import * as styles from "./NoStyleButton.styles";

interface NoStyleButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: ReactNode;
  innerRef?: Ref<HTMLAnchorElement | HTMLButtonElement>;
  to?: To;
}

const NoStyleButton = ({
  children,
  innerRef,
  to,
  type = "button",
  ...rest
}: NoStyleButtonProps) => {
  if (to) {
    return (
      <Link
        css={[styles.noStyle, rest.disabled && styles.disabled]}
        to={to}
        type={type}
        {...rest}
        ref={innerRef as Ref<HTMLAnchorElement>}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      css={[styles.noStyle, rest.disabled && styles.disabled]}
      ref={innerRef as LegacyRef<HTMLButtonElement>}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export type { NoStyleButtonProps };
export { NoStyleButton };
